import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import { Link } from 'gatsby';

const IndexPage = (props) => {
  const countdown = () => {
    const countDate = new Date('December 18, 2021 14:00:00').getTime();
    const now = new Date().getTime();
    const gap = now - countDate;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const year = day * 365;

    const textYear = Math.floor(gap / year);
    const textDay = Math.floor((gap % year) / day);
    const textHour = Math.floor((gap % day) / hour);
    const textMinute = Math.floor((gap % hour) / minute);
    const textSecond = Math.floor((gap % minute) / second);

    return {
      textYear: textYear > 0 ? textYear : 0,
      textDay: textDay > 0 ? textDay : 0,
      textHour: textHour > 0 ? textHour : 0,
      textMinute: textMinute > 0 ? textMinute : 0,
      textSecond: textSecond > 0 ? textSecond : 0,
    };
  };

  const [countdownText, setCountdownText] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      const { textYear, textDay, textHour, textMinute, textSecond } =
        countdown();
      setCountdownText({ textYear, textDay, textHour, textMinute, textSecond });
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <Layout fullMenu location={props.location}>
      <section id="banner">
        <div className="inner">
          {/* <h2>{config.heading}</h2> */}
          <h2>Happily Married</h2>
          <p>December 18, 2021</p>
          {/* <p>{config.subHeading}</p> */}
          <ul className="actions special">
            <li>
              {/* <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll> */}
            </li>
          </ul>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            More Info
          </a>
        </Scroll>
      </section>

      {/* <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2 className="section-title">Wedding Ceremony</h2>
            <p>
              Vietnamese Martyrs Catholic Church
              <br />
              Arlington, Texas
            </p>
          </header>
          <ul className="icons major">
            <li>
              <span className="icon fa-heart major style1">
                <span className="label">Heart</span>
              </span>
            </li>
          </ul>
          <header className="major">
            <h2 className="section-title">Reception</h2>
            <p>
              Thanh Thanh Restaurant
              <br />
              Arlington, Texas
            </p>
          </header>
        </div>
      </section> */}

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2 className="section-title">See our photos!</h2>
            <p>
              <Link to="/photos">
                <button className="button primary">Photos</button>
              </Link>
            </p>
          </header>
          <ul className="icons major">
            <li>
              <span className="icon fa-heart major style1">
                <span className="label">Heart</span>
              </span>
            </li>
          </ul>
        </div>
      </section>

      {/* <section id="two" className="wrapper alt style2">
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/bridge-looking.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Magna primis lobortis
              <br />
              sed ullamcorper
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/shoulder.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Tortor dolore feugiat
              <br />
              elementum magna
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/ringfocus.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Augue eleifend aliquet
              <br />
              sed condimentum
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
      </section> */}

      <section id="three" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>We've been married for</h2>

            {/* <p>
              Aliquam ut ex ut augue consectetur interdum. Donec amet imperdiet
              eleifend
              <br />
              fringilla tincidunt. Nullam dui leo Aenean mi ligula, rhoncus
              ullamcorper.
            </p> */}
          </header>
          <div className="countdown">
            <div className="countdown--group">
              <div className="countdown--box">
                <div className="countdown--box--contents">
                  <div className="countdown--box--contents--top">
                    {countdownText.textYear}
                  </div>
                  <div className="countdown--box--contents--bottom">
                    {countdownText.textYear === 1 ? 'Year' : 'Years'}
                  </div>
                </div>
              </div>
              <div className="countdown--box">
                <div className="countdown--box--contents">
                  <div className="countdown--box--contents--top">
                    {countdownText.textDay}
                  </div>
                  <div className="countdown--box--contents--bottom">Days</div>
                </div>
              </div>
              <div className="countdown--box">
                <div className="countdown--box--contents">
                  <div className="countdown--box--contents--top">
                    {countdownText.textHour}
                  </div>
                  <div className="countdown--box--contents--bottom">Hours</div>
                </div>
              </div>

              {/* <div className="countdown--group"> */}
              <div className="countdown--box">
                <div className="countdown--box--contents">
                  <div className="countdown--box--contents--top">
                    {countdownText.textMinute}
                  </div>
                  <div className="countdown--box--contents--bottom">
                    Minutes
                  </div>
                </div>
              </div>
              <div className="countdown--box">
                <div className="countdown--box--contents">
                  <div className="countdown--box--contents--top">
                    {countdownText.textSecond}
                  </div>
                  <div className="countdown--box--contents--bottom">
                    Seconds
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* <ul className="features">
            <li className="icon fa-paper-plane">
              <h3>Arcu accumsan</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
            <li className="icon solid fa-laptop">
              <h3>Ac Augue Eget</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
            <li className="icon solid fa-code">
              <h3>Mus Scelerisque</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
            <li className="icon solid fa-headphones-alt">
              <h3>Mauris Imperdiet</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
            <li className="icon fa-heart">
              <h3>Aenean Primis</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
            <li className="icon fa-flag">
              <h3>Tortor Ut</h3>
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
            </li>
          </ul> */}
        </div>
      </section>

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <p className="cta--quote">
              Love is patient, love is kind. It is not jealous, love is not
              pompous, it is not inflated, it is not rude, it does not seek its
              own interests, it is not quick-tempered, it does not brood over
              injury, it does not rejoice over wrongdoing but rejoices with the
              truth. It bears all things, believes all things, hopes all things,
              endures all things. Love never fails.
            </p>
            <p className="cta--quote--bottom">1 Corinthians 13: 4-8</p>
          </header>
          {/* <ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul> */}
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
